import { Status } from '../enums/projectStatus.js';

export const personalProjects = [
  {
    id: 1,
    title: 'Gamepad to Keyboard/Mouse',
    description: 'Adds the ability to control Windows using a standard gamepad, with customizable controls.',
    image: "/images/XboxGitCap.PNG",
    githubUrl: 'https://github.com/AlexSkillman10/XBOXtoKeyMouse',
    projectUrl: null,

    fullDescription: "Adds the ability to control Windows using a standard gamepad, with customizable controls.",
    projectStatus: Status.CompleteUpdate,
  },
  {
    id: 2,
    title: 'This Website',
    description: 'My first real foray in React, this website was custom built off of npx create-react-app to host and present some of my work.',
    image: "/images/TempWebCap.PNG",
    url: 'https://example.com/project2',
    githubUrl: 'https://github.com/AlexSkillman10/PortSite',

    fullDescription: "My first real foray in React, this website was custom built off of npx create-react-app to host and present some of my work.",
    projectStatus: Status.CompleteMaintenance,
  },
];

export const schoolProjects = [
  {
    id: 1,
    title: 'Pitivi Aspect Ratio Features',
    description: 'n/a',
    image: 'https://via.placeholder.com/200x200',
    url: null,
    githubUrl: null,
    fullDescription: "n/a",
    projectStatus: Status.Complete,
  },
  {
    id: 3,
    title: 'Boost Board Mobile',
    description: 'n/a',
    image: 'https://via.placeholder.com/200x200',
    url: null,
    githubUrl: null,
    fullDescription: "n/a",
    projectStatus: Status.Complete,
  },
  {
    id: 5,
    title: 'UNL Travel Planning App',
    description: 'n/a',
    image: 'https://via.placeholder.com/200x200',
    url: null,
    githubUrl: null,
    fullDescription: "n/a",
    projectStatus: Status.Complete,
  },
  {
    id: 6,
    title: 'UNL Controlled Burn Planner',
    description: 'n/a',
    image: 'https://via.placeholder.com/200x200',
    url: null,
    githubUrl: null,
    fullDescription: "n/a",
    projectStatus: Status.Complete,
  },
];

export const professionalProjects = [
  {
    id: 1,
    title: 'Automated Lumber Warehouse Inventory',
    description: 'Software to store and automatically track inventory positions throughout lumber distribution warehouses.',
    image: '/images/dmsi_software.PNG',
    url: 'https://example.com/project2',
    githubUrl: 'https://github.com/AlexSkillman10/XBOXtoKeyMouse',

    fullDescription: "Project Manager for a student team developing a vehicle mounted device along with software to store and automatically track inventory positions throughout lumber distribution warehouses. I have developed significant portions of the backend and logic of the automated system, including the specification for computer vision decision making and modules for calculating the position and distance of lumber tags in scanning images.",
    projectStatus: Status.InProgress,
  },
];