import React from 'react';
import './StatusCard.css';
import { Status } from '../../../public/enums/projectStatus.js';

const StatusCard = ({ status }) => {
  let color;
  switch (status) {
    case Status.Deprecated:
      color = 'deprecated';
      break;
    case Status.InProgress:
      color = 'inProgress';
      break;
    case Status.Complete:
      color = 'complete';
      break;
    case Status.CompleteMaintenance:
      color = 'completeMaintenance';
      break;
    case Status.CompleteUpdate:
      color = 'completeUpdate';
      break;
    default:
      color = 'gray';
  }

  return (
    <div className={`status-box ${color}`}>
      <p className="project-status">{status}</p>
    </div>
  );
};

export default StatusCard;