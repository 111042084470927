import React, { useRef } from 'react';
import './modal.css';
import StatusCard from './statusCard/StatusCard.js';

const Modal = ({ isOpen, onClose, project }) => {
  const modalRef = useRef();

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOutsideClick}>
      <div className="modal-content" ref={modalRef}>
        <h2>{project.title}</h2>
        <img src={project.image} alt={project.title} />
        <StatusCard status={project.projectStatus} />
        <p>{project.fullDescription}</p>
      </div>
    </div>
  );
};

export default Modal;