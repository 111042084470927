import React, { useState } from 'react';
import { schoolProjects, professionalProjects, personalProjects } from './public/data/projectData.js';
import './App.css';
import Modal from './components/projectCard/modal';


function App() {

  const [currentProjects, setCurrentProjects] = useState(personalProjects);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  
  const handleCategoryClick = (category) => {
    const categories = document.querySelectorAll(".category");
    const slider = document.querySelector(".slider");
    let position = 0;
  
    categories.forEach((cat, index) => {
      if (cat.innerText.toLowerCase() === category) {
        position = cat.offsetLeft;
      }
    });

    slider.style.left = position + "px";
  
    if (category === "school") {
      slider.style.width = "62px";
      setCurrentProjects(schoolProjects);
    } else if (category === "personal") {
      slider.style.width = "79px";
      setCurrentProjects(personalProjects);
    } else if (category === "professional") {
      slider.style.width = "111px";
      setCurrentProjects(professionalProjects);
    }
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  return (
    <div className="page-container">
      <div>

        <div className="heading-container">
          <div className="heading-left">
            <h1 className='heading-large'>Alex Skillman</h1>
            <h1 className='heading-medium'>About Me</h1>
          </div>
        </div>

        <div className="about-container">
          <div className="about-box">
            <img src="https://via.placeholder.com/200x200" alt="Your Name" />
            <p>
            Hi!
            <br></br>
            <br></br>
            I'm Alex Skillman, a Junior Software Engineering student at the University of Nebraska - Lincoln.
            I am currently acting as a project manager for a student team developing software to automate inventory tracking in lumber distribution warehouses. This project is actively expanding my technical skills and has allowed me to spend time developing interesting solutions to unique problems, which has been a fun experience. I am excited to continue updating information about the project's progress!
            </p>
          </div>
        </div>

        <div className="under-construction-tag">
          <span role="img" aria-label="hammer">🔨</span> This website is still under construction. Projects are missing and may lack information.
        </div>

        <div className="sticky-container">
          <div className="heading-container">
            <div className="heading-left">
              <h1 className='heading-medium'>Projects</h1>
            </div>
          </div>
          <div className="heading-container">
            <div className="heading-left">
              <div className="heading-small">
                <div className="categories">
                <div className="category" onClick={() => handleCategoryClick('personal')}>Personal</div>
                  <div className="category" onClick={() => handleCategoryClick('school')}>School</div>
                  <div className="category" onClick={() => handleCategoryClick('professional')}>Professional</div>
                  <div className="slider"></div>
                </div>              
              </div>
            </div>
          </div>
        </div>

        <div className="grid-container">
          {currentProjects.map((project) => (
            <div key={project.id} className="grid-item">
              <img src={project.image} alt={project.title} />
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <div className="button-container">
                <button onClick={() => handleProjectClick(project)}>Project Details</button>
                <a href={project.githubUrl} target="_blank" rel="noopener noreferrer">Github</a>
              </div>
            </div>
          ))}
        </div>
        
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} project={selectedProject} />
    </div>
  );
}

export default App;
